<template>
    <div ref="containerRef" class="relative flex w-full lg:grid">
        <slot name="left" />

        <!-- Resizer -->
        <div
            v-if="!disabled"
            ref="resizerRef"
            :class="[
                'group z-[1] mx-1 flex w-2 cursor-ew-resize select-none justify-center gap-1 rounded-md hover:bg-gray-200',
                isMouseDown && 'bg-gray-300',
            ]"
            @mousedown.stop.passive="onMouseDown"
            @touchstart.stop.passive="onMouseDown"
        >
            <span
                class="sticky top-80 flex h-10 w-5 shrink-0 items-center justify-center self-start rounded-md bg-white text-gray-500 shadow-xl ring-2 ring-gray-200 transition-transform group-hover:scale-110"
            >
                <svg
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z"
                            fill="currentColor"
                        />
                    </g>
                </svg>
            </span>
        </div>

        <slot name="right" :is-resizing="isMouseDown" />

        <!-- We need this overlay when dragging because when it moves through iframes it emits @mouseup on chrome -->
        <div v-if="isMouseDown" class="fixed inset-0 z-50" />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

type ContainerFractions = {
    left: number
    right: number
}

interface MinWidthInPixels {
    /** Minimum width in pixels for the left column */
    left: number
    /** Minimum width in pixels for the right column */
    right: number
}

const resizerWidth = 15
let startX = 0
let startLeftWidth = 0

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    minWidth: {
        type: Object as PropType<MinWidthInPixels>,
        required: true,
    },
    fractions: {
        type: Object as PropType<ContainerFractions>,
        default: () => ({ left: 1, right: 0.5 }),
    },
    cacheKey: {
        type: String,
        default: '',
    },
})

const slots = useSlots()

const fractionsCache = props.cacheKey
    ? useLocalStorage<ContainerFractions>(
          `resizable-cache-${props.cacheKey}`,
          props.fractions,
      )
    : undefined

const isMouseDown = ref(false)
const containerRef = ref<HTMLDivElement | null>(null)
const resizerRef = ref<HTMLDivElement | null>(null)

onMounted(() => {
    nextTick(() => {
        if (slots.right && !props.disabled) {
            window.addEventListener('resize', handleWindowResize)
        }

        setInitialGridColumns()
    })
})

onUnmounted(() => {
    window.removeEventListener('resize', handleWindowResize)
})

function onMouseDown(event: MouseEvent | TouchEvent) {
    isMouseDown.value = true

    const { left } = getSlotNodes()

    // https://stackoverflow.com/questions/58473921/why-cant-i-use-touchevent-in-safari
    if ('touches' in event) startX = event.touches[0].clientX
    else startX = event.clientX

    startLeftWidth = left.getBoundingClientRect().width

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
}

function onMouseUp() {
    isMouseDown.value = false

    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
}

function onMouseMove(e: MouseEvent) {
    const currentX = e.clientX
    const distanceX = currentX - startX
    const containerWidth = containerRef.value!.getBoundingClientRect().width

    const { leftFr, rightFr } = calculateGridColumns(
        startLeftWidth,
        distanceX,
        containerWidth,
    )

    const leftSize = `minmax(${props.minWidth.left}px, ${leftFr}fr)`
    const rightSize = `minmax(${props.minWidth.right}px, ${rightFr}fr)`

    containerRef.value!.style.gridTemplateColumns = `${leftSize} ${resizerWidth}px ${rightSize}`
}

// Keep grid column proportions when resizing the window while still maintaining the minimum width constraints
function handleWindowResize() {
    if (breakpoint.isSmaller('lg') || props.disabled) return

    requestAnimationFrame(() => {
        const containerWidth = containerRef.value!.getBoundingClientRect().width
        const { left } = getSlotNodes()

        const newLeftWidth = left.getBoundingClientRect().width

        const { leftFr, rightFr } = calculateGridColumns(
            newLeftWidth,
            0,
            containerWidth,
        )

        const leftSize = `minmax(${props.minWidth.left}px, ${leftFr}fr)`
        const rightSize = `minmax(${props.minWidth.right}px, ${rightFr}fr)`
        const gridTemplateColumns = `${leftSize} ${resizerWidth}px ${rightSize}`

        containerRef.value!.style.gridTemplateColumns = gridTemplateColumns
    })
}

/* Calculate the fr units for left and right columns */
function calculateGridColumns(
    startLeftWidth: number,
    distanceX: number,
    containerWidth: number,
) {
    let newLeftWidth = startLeftWidth + distanceX
    let newRightWidth = containerWidth - newLeftWidth - resizerWidth

    // Ensure the left and right widths respect their respective minimum widths
    if (newLeftWidth < props.minWidth.left) {
        newLeftWidth = props.minWidth.left
        newRightWidth = containerWidth - newLeftWidth - resizerWidth
    }

    if (newRightWidth < props.minWidth.right) {
        newRightWidth = props.minWidth.right
        newLeftWidth = containerWidth - newRightWidth - resizerWidth
    }

    // Calculate total available width for both columns after applying minimum width constraints
    const totalAvailableWidth = containerWidth - resizerWidth // Subtracting resizer width

    // Calculate fr units for left and right columns
    const { left, right } = getInitialFractions()
    const totalFrUnits = left + right

    const leftFr = (newLeftWidth / totalAvailableWidth) * totalFrUnits
    const rightFr = (newRightWidth / totalAvailableWidth) * totalFrUnits

    if (fractionsCache) {
        fractionsCache.value = { left: leftFr, right: rightFr }
    }

    return { leftFr, rightFr }
}

function setInitialGridColumns() {
    if (!slots.right || props.disabled) {
        containerRef.value!.style.gridTemplateColumns = '1fr'

        return
    }

    const { left, right } = getInitialFractions()

    const leftSize = `minmax(${props.minWidth.left}px, ${left}fr)`
    const rightSize = `minmax(${props.minWidth.right}px, ${right}fr)`
    const gridTemplateColumns = `${leftSize} ${resizerWidth}px ${rightSize}`

    containerRef.value!.style.gridTemplateColumns = gridTemplateColumns
}

function getInitialFractions() {
    return fractionsCache ? fractionsCache.value : props.fractions
}

function getSlotNodes() {
    const children = containerRef.value!.children

    return { left: children[0], right: children[2] }
}
</script>
